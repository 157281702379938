<template>
    <div class="Grade">
        <main-header menuActive="statistics" />

        <div id="contentsWrap">
            <div class="container sub">
                <div class="contentHead">
                    <h2>시설별 통계현황</h2>
                </div>
                <div class="row searchBtn">
                    <div class="link_tab pull-left">
                        <div class="InsulatorTab">
                            <ul>
                                <li v-for="(data, index) in head_data" :key="index" :class="{'on':(data.code==sort_key)}">
                                    <a @click="sort_key = data.code">
                                        <span>{{data.name}}</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="pull-right">
                        <a class="btn8" @click="excelDownFunc" style="color:#fff">엑셀다운로드</a>
                    </div>
                </div>
                <div class="row">
                    <div class="form_box">
                        <div class="row no-pdd-top">
                            <div class="searchBox">

                                <div class="SearchDateBox">
                                    <label class="searchTitle">기간</label>
                                    <select class="select_ansimi width150" v-model="sch_select_type">
                                      <option v-for="(data, index) in searchConditionFList" :key="index" :value="data.code">{{data.name}}</option>
                                    </select>
                                    <div class="dataGroup">
                                        <datepicker :input-class='["input", "type2", "width150"]' format="yyyy-MM-dd" :language="ko" v-model="sch_date_start"></datepicker>
                                        <span class="icon_calendar"></span>
                                    </div>
                                    <span class="date_bar"> ~ </span>
                                    <div class="dataGroup">
                                        <datepicker :input-class='["input", "type2", "width150"]' format="yyyy-MM-dd" :language="ko" v-model="sch_date_end"></datepicker>
                                        <span class="icon_calendar"></span>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="row text-center">
                            <a class="btn7 mrg-right-10" @click="searchListFunc()">조회</a>
                        </div>
                    </div>
                </div>
                <div v-for="(data, index) in head_data" :key="index">
                    <div class="row" v-if="data.code == sort_key">
                        <div class="subHead">
                            <h3># {{ data.name }}</h3>
                        </div>
                        <table class="table table-profile">
                            <tbody>
                            <tr>
                                <th class="field text-center font-weight-bold">날짜</th>
                                <th class="field text-center font-weight-bold"
                                    v-for="(data2, index2) in list_header" :key="index2"
                                >
                                    {{ data2 }}
                                </th>
                                <th class="field text-center font-weight-bold">합계</th>
                            </tr>
                            <tr v-for="(data3, index3) in list_data" :key="index3">
                                <td class="text-center" v-for="(data4, index4) in data3" :key="index4">
                                    <template v-if="index4 == 'fRegDate'">
                                        {{ data4 }}
                                    </template>
                                    <template v-else>
                                        <a class="text-link" @click="searchDetailFunc(data.name, data3.fRegDate, index4)">{{ data4 }}</a>
                                    </template>
                                </td>
                                <td class="text-center font-weight-bold">{{ regSumList[index3] }}</td>
                            </tr>
                            <tr>
                              <th class="field text-center font-weight-bold">총계</th>
                              <th class="field text-center font-weight-bold"
                                  v-for="(data2, index2) in sumList" :key="index2"
                              >
                                {{ data2 }}
                              </th>
                              <th class="field text-center font-weight-bold">-</th>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <main-footer />
    </div>
</template>

<script>
import mainHeader from '@/components/MainHeader.vue';
import mainFooter from '@/components/MainFooter.vue';
import datepicker from 'vuejs-datepicker';
import {ko} from 'vuejs-datepicker/dist/locale'

export default {
    name: 'confirmedPerson',
    data() {
        return {
            ko : ko,
            sch_date_start : this.$moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format('YYYY-MM-DD'),
            sch_date_end : this.$moment(new Date(new Date().getFullYear(), new Date().getMonth()+1, 0)).format('YYYY-MM-DD'),

            sch_select_type : 'assigned',

            head_data : [],
            list_data : [],
            footer_data : [],

            // sumList : [],

            sum_data : 0,
            total_sum : 0,

            sort_key : '',        // Tab 정보

        }
    },
    components: { mainHeader, mainFooter, datepicker },
    // 계산된 속성
    computed: {

    },
    // 감시자
    watch: {
    },
    // $el 생성전
    created() {
        this.checkLogin();
    },
    // $el 생성후
    mounted() {
        this.init();
        /* init() 이후 실행되도록 initResult() 내부로 이동.
        if (this.$route.params.sch_date_start){
            this.sch_date_start = this.$route.params.sch_date_start;
            this.sch_date_end = this.$route.params.sch_date_end;
            this.sch_select_type = this.$route.params.sch_select_type;
            this.sort_key = this.$route.params.sort_key;

            this.searchListFunc();
        }else{
            this.searchListFunc();
        }
        */
    },
    methods: {
        init(){
            this.ajaxGet('nocCommon/code?params=', null, 'initResult');
        },
        // 리스트 검색
        searchListFunc(){
            let params = `{
                "fromRegDt": "${this.dateToString(this.sch_date_start)}",
                "toRegDt": "${this.dateToString(this.sch_date_end)}",
                "selectType": "${this.sch_select_type}",
                "tapCode": "${this.sort_key !== "" ? this.sort_key : "G0401"}"
            }`;
            let queryString = encodeURIComponent(params); // params를 URL 인코딩하여 쿼리 문자열로 변환
            this.ajaxGet('nocStatistics/facility?params=' + queryString, null, 'searchListFuncResult');

            /*
            let formData = new FormData();
            formData.append('api_name', 'statisticsFasility');
            formData.append('sch_date_start', this.$moment(this.sch_date_start).format('YYYY-MM-DD'));
            formData.append('sch_date_end', this.$moment(this.sch_date_end).format('YYYY-MM-DD'));
            formData.append('sch_select_type', this.sch_select_type);
            formData.append('f_admin_id', this.userDataAdminId);

            this.ajaxPost('', formData,'searchListFuncResult');
            */
        },
        initResult(response){
            let result = response;
            this.head_data = result.fasilityCodeList;

            if (this.$route.params.sch_date_start){
                this.sch_date_start = this.$route.params.sch_date_start;
                this.sch_date_end = this.$route.params.sch_date_end;
                this.sch_select_type = this.$route.params.sch_select_type;
                this.sort_key = this.$route.params.sort_key;

                this.searchListFunc();
            }else{
                this.searchListFunc();
            }
        },
        searchListFuncResult(response) {
            let result = response;
            // this.head_data = result.head_data;
            this.list_header = result.header;
            this.list_data = result.list;

            this.sumList = [];
            let sumList = this.sumList;
            this.regSumList = [];
            let regSumList = this.regSumList;

            this.list_data.forEach((obj, index) => {
                let i = 0;
                for (let key in obj) {
                    if (key !== "fRegDate") {
                        if (!sumList[i]) {
                            sumList[i] = 0; // 초기값 설정
                        }
                        sumList[i] += obj[key];
                        i++;

                        if (!regSumList[index]) {
                            regSumList[index] = 0; // 초기값 설정
                        }
                        regSumList[index] += obj[key];
                    }
                }
            })

            if (!this.sort_key){
                this.sort_key = this.head_data[0].code;
            }

        },
        excelDownFunc(){
            console.log("엑셀다운로드");
            /*
            let url=this.rootUrl+'/_excel/excel_statistics_fasility.php?1=1';
            if(this.sch_date_start && this.sch_date_end){
                url += '&sch_date_start='+this.$moment(this.sch_date_start).format('YYYY-MM-DD');
                url += '&sch_date_end='+this.$moment(this.sch_date_end).format('YYYY-MM-DD');
            }

            if(this.sch_select_type){
              url += '&sch_select_type='+this.sch_select_type;
            }else {
              url += '&sch_select_type=1';
            }
            this.excelExport(url);
            */

        
            let params = `{
                "fromRegDt": "${this.dateToString(this.sch_date_start)}",
                "toRegDt": "${this.dateToString(this.sch_date_end)}",
                "selectType": "${this.sch_select_type}",
                "tapCode": "${this.sort_key !== "" ? this.sort_key : "G0401"}"
            }`;

            let queryString = encodeURIComponent(params); // params를 URL 인코딩하여 쿼리 문자열로 변환
            this.ajaxGet('nocStatistics/facility?params=' + queryString, null, 'downloadExcel');

        },
        downloadExcel(response) {
            if (!response || !response.list || response.list.length === 0) {
                console.error('Empty or undefined dataList in response');
                return;
            }

            let dataList = response.list; // 데이터 가져오기
            console.log(dataList);
            
            let excelData = [];


            // 엑셀 헤더 추가
            let headerRow = ['일자', ...response.header];
            excelData.push(headerRow);

            let sum = 0;

            // 엑셀 데이터 추가
            let totalColumn = Array.from({ length: response.header.length + 1 }, () => 0);
            let rowDataTotal = 0;
            let dataTotals = 0;

            dataList.forEach(item => {
                let dataRow = [item.fRegDate];
                
                response.header2.forEach(headerKey => {
                    let value = item[headerKey];
                    dataRow.push(value);
                    rowDataTotal += value;
                    totalColumn[response.header2.indexOf(headerKey)] += value;
                });
                dataRow.push(rowDataTotal); // 열 합계 추가
                excelData.push(dataRow);
            });

            // 각 열의 합계를 마지막 열에 추가
            excelData.forEach((row, rowIndex) => {
                if (rowIndex === 0) { row.push('합계');} 
                else {             
                let rowSum = row.slice(1, -1).reduce((acc, curr) => acc + curr, 0); // 행의 합계 계산
                row.push(rowSum);
                }
            });

            // 총계 행 추가
            let totalRow = ['총계', ...totalColumn];
            excelData.push(totalRow);

            console.log(excelData);

            // 엑셀 라이브러리 가져오기
            const XLSX = require('xlsx');

            // 엑셀 파일 생성
            let ws = XLSX.utils.aoa_to_sheet(excelData);
            let wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

            // 엑셀 파일 다운로드
            XLSX.writeFile(wb, '병상배정통계_시설별.xlsx');
        },
        // 상세페이지
        searchDetailFunc(title, date, idx){
            let data={};
            data.sch_date_start = this.sch_date_start;
            data.sch_date_end = this.sch_date_end;
            data.sch_select_type = this.sch_select_type;
            data.title = title;
            data.date = date;
            data.idx = idx.startsWith("f") ? idx.substring(1) : idx;
            data.sort_key = this.sort_key;
            data.sch_type = 'fasility';

            this.$router.push({ name: 'AdminStatisticsDetail', params: {data} })
        },
        dateToString(dateString) {
            // const dateString = "Fri Jan 01 2021 09:00:00 GMT+0900 (한국 표준시)";
            const date = new Date(dateString); // Date 객체 생성
            const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())}`; // 년, 월, 일을 추출하여 원하는 형식으로 조합

            // 월과 일이 한 자리 수일 경우 앞에 0을 붙이는 함수
            function padZero(num) {
                return num < 10 ? '0' + num : num;
            }

            return formattedDate;
        },
    }
}
</script>

<style scoped>
.link_tab li a {
    min-width: 80px;
    padding: 0 10px;
}
</style>
